//@flow
//Core
import React, { useState, useEffect } from 'react';
import get from 'lodash.get';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Box } from '@material-ui/core';
import formatDate from 'date-format';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { getColumnArticles } from '../redux/merchant/actions';
import { Layout } from '../components';
import {
  BreadcrumbsSec,
  ArticleSec,
  RecommendSec,
} from '../components/Column/Article';
import { getOgData } from '../utils';
import styles from '../styles/pages/column.module.scss';

export const query = graphql`
  query($tagsId: [String], $contentfulId: String) {
    allContentfulComponentArticle(
      limit: 1000
      filter: {
        tags: { elemMatch: { id: { in: $tagsId } } }
        contentful_id: { ne: $contentfulId }
      }
    ) {
      edges {
        node {
          title
          subtitle
          tags {
            title
          }
          contentful_id
          date
          blogSection {
            urlKey
          }
          previewImage {
            file {
              url
            }
          }
          tags {
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        ogImage
        twitterImage
        siteUrl
      }
    }
  }
`;

const Article = ({ pageContext, data, location }) => {
  const [imgPopupState, setImgPopupState] = useState({
    isOpen: false,
    url: null,
  });

  const sessionUrl = get(pageContext, 'blogSection.urlKey');
  const breadcrumbs = [
    {
      title: 'お知らせ',
      url: '/merchant/column/',
    },
    {
      title: get(pageContext, 'title'),
    },
  ];

  const handleImgClick = (event) => {
    setImgPopupState({
      isOpen: true,
      url: event.target.src,
    });
  };

  const handleClosePopup = () => {
    setImgPopupState({
      isOpen: false,
      url: null,
    });
  };

  const dispatch = useDispatch();
  const columnArticles = useSelector((state) => state.merchantPages.column);

  useEffect(() => {
    dispatch(getColumnArticles());
  }, [dispatch]);

  const isMerchant = location?.pathname?.includes('/merchant/');

  return (
    <Layout>
      <Helmet
        title={get(pageContext, 'title')}
        meta={getOgData({
          img: get(pageContext, 'previewImage'),
          title: get(pageContext, 'title'),
          siteMetadata: get(data, 'site.siteMetadata'),
        })}
      />
      <Box className={styles.articlePage} spacing={{ xs: 0.5 }}>
        <Box className={styles.articlePageBg} />
        <Container maxWidth="md">
          <Box className={styles.paper}>
            <BreadcrumbsSec
              items={breadcrumbs}
              tag={get(pageContext, 'tags[0]', {})}
            />
            <ArticleSec
              title={get(pageContext, 'title')}
              subtitle={get(pageContext, 'subtitle')}
              date={formatDate(
                'yyyy/MM/dd',
                new Date(
                  get(pageContext, 'date') || get(pageContext, 'createdAt')
                )
              )}
              author={get(pageContext, 'author')}
              tags={get(pageContext, 'tags', [])}
              content={get(pageContext, 'content.json')}
              imgPopupState={imgPopupState}
              handleImgClick={handleImgClick}
              handleClosePopup={handleClosePopup}
              shareUrl={location.href}
              sessionUrl={sessionUrl}
            />
          </Box>
          {columnArticles && !!columnArticles.length && (
            <RecommendSec
              recommendedArticles={columnArticles.slice(0, 3)}
              isMerchant={isMerchant}
            />
          )}
        </Container>
      </Box>
    </Layout>
  );
};

export default Article;
